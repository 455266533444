"use strict";
export default function specialDisplay() {
    $(function() {
        const htmlEscape = (str) => {
            if (!str) return;
            return str.replace(/[<>&"'`]/g, (match) => {
                const escape = {
                    '<': '&lt;',
                    '>': '&gt;',
                    '&': '&amp;',
                    '"': '&quot;',
                    "'": '&#39;',
                    '`': '&#x60;'
                };
                return escape[match];
            });
        }
        // console.log(htmlEscape('& < > ` " ' + "'"));
        // => &amp; &lt; &gt; &#x60; &quot; &#39;
        $(".parts_items_lists").each(function() {
            $(this).find(".__item").each(function() {
                let infoText, infoTextArray;
                infoText = $(this).find(".__info").text();
                infoText=htmlEscape(infoText)
                infoTextArray = infoText.split(',');

                let reInfoText = "";
                for (let index = 0; index < infoTextArray.length; index++) {
                    reInfoText += '<span class="__row">'
                    reInfoText += infoTextArray[index];
                    reInfoText += '</span>';
                }
                $(this).find(".__info").html(reInfoText)
            });
        })

        $(".p_detail_contents").find(".p_detail_main").find(".__top_area").each(function() {
            let infoText, infoTextArray;
            infoText = $(this).find(".__info").text();
            infoText=htmlEscape(infoText)     
            infoTextArray = infoText.split(',');
            
            let reInfoText = "";
            for (let index = 0; index < infoTextArray.length; index++) {       
                reInfoText += '<span>'
                reInfoText += infoTextArray[index];
                reInfoText += '</span>';
            }
            $(this).find(".__info").html(reInfoText)
        })
    })
}