"use strict";

export default function main() {

    const windowHeight = $(window).height();
    $(function() {
        hamburger();
        headerLoginBtn();
        headerNavHover();
    });

    function hamburger() {

        $("#hamburgerBtn").on('click', function() {
            $(this).toggleClass("open");
            $("#l_side").slideToggle()
            if ($(this).hasClass("open")) {
                $("#hamburgerBtnText").text("Close")
            } else {
                $("#hamburgerBtnText").text("Menu")
            }
        });

    }

    function headerLoginBtn() {
        $("#loginBtn").on('click', function() {
            $("#loginBtnContents").slideToggle()
        })
        $("#loginBtnContents").find(".__bg").on('click', function() {
            $("#loginBtnContents").slideToggle()
        })
    }

    // pc nav hoverの挙動
    function headerNavHover() {
        $("#headerNavPc").find(".__parent").each(function() {
            $(this).on('mouseenter', function() {
                $(this).find(".__children").addClass("on")
            })
            $(this).on('mouseleave', function() {
                $(this).find(".__children").removeClass("on")
            })
        })
    }
    //topへスムースscroll
    $(function() {
        const pageTop = $('#toTop');
        $(window).on("scroll", function() {
            if ($(this).scrollTop() > 300) {
                pageTop.fadeIn();
            } else {
                pageTop.fadeOut();
            }
        });
        pageTop.on("click", function() {
            $('body, html').animate({
                scrollTop: 0
            }, 500);
            return false;
        });
    });

    //scroll始まったらheaderを薄くする
    $(function() {
        $(window).on("scroll", function() {
            headerScroll()
        });

        function headerScroll() {
            if ($(window).scrollTop() > 100) {
                $('#header').addClass('scroll')
            } else {
                $('#header').removeClass('scroll')
            }
        }
    });
    //faqページ　scrollでnavにクラスをつける
    $(function() {
        const pageFaq = $(".p_faq");
        if (pageFaq.length > 0) {
            $(window).on("scroll", function() {
                navAddClass()
            });

            function navAddClass() {
                if ($(window).scrollTop() > 350) {
                    pageFaq.find(".p_c_nav").addClass("addBoxShadow")
                } else {
                    pageFaq.find(".p_c_nav").removeClass("addBoxShadow")
                }
            }
        }

    });
    // -------------------------------------------------animation
    function animation() {
        $(function() {

            $(".animation").each(function() {
                let $animation = $(this),
                    animationOffsetTop = $animation.offset().top;

                const adjust = -150;

                if ($(window).scrollTop() > animationOffsetTop - windowHeight - adjust) {

                    $($animation).addClass('fuwa');
                }
            });
        });
    }
    $(window).on('scroll', function() {
        animation();
    });
    animation();
    let lastInnerWidth = $(window).innerWidth();
    $(window).on('resize', function() {
        // 現在と前回の横幅が違う場合だけ実行
        if (lastInnerWidth != $(window).innerWidth()) {
            // 横幅を記録しておく
            lastInnerWidth = window.innerWidth;
            animation();
        }
    });
    $(window).on('myEvent', function() {
        // 'myEvent' が発生した時に実行したい処理
        animation();
    });
    $(function() {

        //URLのハッシュ値を取得
        var urlHash = location.hash;
        //ハッシュ値があればページ内スクロール
        if (urlHash) {
            //スクロールを0に戻す
            $('body,html').stop().scrollTop(0);
            setTimeout(function() {
                //ロード時の処理を待ち、時間差でスクロール実行
                scrollToAnker(urlHash);
            }, 10);
        }

        //通常のクリック時
        $('a[href^="#"]').click(function() {
            //ページ内リンク先を取得
            var href = $(this).attr("href");
            //リンク先が#か空だったらhtmlに
            var hash = href == "#" || href == "" ? 'html' : href;
            //スクロール実行
            scrollToAnker(hash);
            //リンク無効化
            return false;
        });
        //faqページのスマホ時のselect
        $('#navSelect').on('change', function(e) {
            let val = $(this).val();
            val = '#' + val;
            scrollToAnker(val);
        })
        // 関数：スムーススクロール
        // 指定したアンカー(#ID)へアニメーションでスクロール
        function scrollToAnker(hash) {
            if (hash.startsWith(`#makeshop`)) {
                return;
            }
            if (hash.startsWith(`#CategoryArea`)) {
                return;
            }
            var target = $(hash);
            var position = target.offset().top;
            const speed = 500;

            if ($(window).width() > 1250) {
                var adjust = 175;
            } else {
                var adjust = 72;
            }
            var position_adjust = position - adjust;
            $("html, body").animate({
                scrollTop: position_adjust
            }, speed, "swing");
        }

    });
    //.m-link--empty classをもってるaタグはいったんリンクの挙動を止める
    $(function() {
        $(".m-link--empty").on('click', function() {
            return false;
        });
    });

}