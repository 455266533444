"use strict";
export default function side() {
    $(function() {

        $(".side_category").find(".__has_child").each(function() {
            let target = $(this);
            target.find(".__toggle").on('click', function() {
                $(this).toggleClass("on")
                target.find(".__child_lists").toggleClass("active")
                target.find(".__child_lists").slideToggle();
            })
        })

        
    })
}