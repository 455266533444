"use strict";

export default function search() {
    $(function() {
        // 検索フォーム
        $('.parts_search_area').each(function() {
            let current = $(this);
            current.find(".__input").on('keypress', function(e) {
                if (e.key == 'Enter') {
                    current.find('.__btn')[0].click();
                }
            });
        })
    })

}