"use strict";


// import Swiper bundle with all modules installed
import Swiper from 'swiper/bundle';
// import styles bundle
import 'swiper/css/bundle';

export function topSwiper() {
    /**
     * mv
     */
    const topMvSwiper = new Swiper("#topMvSwiper", {
        loop: true,
        loopAdditionalSlides: 1,
        spaceBetween: 20,
        autoplay: {
            delay: 5000,
            disableOnInteraction: false,
        },
        speed: 2000,
        watchSlidesProgress: true,
        pagination: {
            el: '#topMvSwiperPagination',
            // clickable: true,
        },
        watchOverflow: true,
    });
}

export function detailSwiper() {
    /**
     * detail
     */
    var detailSwiperThumbs = new Swiper("#detailSwiperThumbs", {
        // loop: true,
        spaceBetween: 12,
        slidesPerView: 4,
        freeMode: true,
        watchSlidesProgress: true,

    });
    var detailSwiperMain = new Swiper("#detailSwiperMain", {
        spaceBetween: 10,
        loop: true,
        loopAdditionalSlides: 1,
        speed: 2000,
        watchSlidesProgress: true,
        autoplay: {
            delay: 5000,
            disableOnInteraction: false,
        },
        thumbs: {
            swiper: detailSwiperThumbs,
        },
        pagination: {
            el: '#detailSwiperMainPagination',
            clickable: true,
        },
    });
}