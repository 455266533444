"use strict";
//theme_original
import themeOriginal from './theme_original/wabisabi';
/**
 * JS
 */
//common
import common from './modules/common';
import side from './modules/side';
import search from './modules/search';
import specialDisplay from './modules/special_display';

//swiper
import {
    topSwiper,
} from './modules/my-swiper';
jQuery(function($) {

    $(function() {
        themeOriginal();
        common();
        side();
        search();
        topSwiper();
        specialDisplay()
    })
});

/**
 * CSS
 */
// import styles bundle
import '../scss/top.scss';